// form file upload button upload/pannel

.uploadcare--widget__button.uploadcare--widget__button_type_open,
.uploadcare--button_primary {
	// width: 250px;
	color: #0d165f;
	letter-spacing: 1px;
	text-transform: uppercase;
	background-image: linear-gradient(to right, #ffc1ae, #ffd8ce 63%);
	border-radius: 100px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: 700;
	text-decoration: none;
	transition: all .25s ease-in-out;
	display: flex;
	box-shadow: 7px 7px 15px rgba(55, 84, 170, .15), -7px -7px 20px rgba(255, 255, 255, 1), inset 0px 0px 4px rgba(255, 255, 255, .2), inset 7px 7px 15px rgba(55, 84, 170, 0), inset -7px -7px 20px rgba(255, 255, 255, 0), 0px 0px 4px rgba(255, 255, 255, 0) !important;

	&:hover {
		box-shadow: 7px 7px 15px rgba(55, 84, 170, .15), -7px -7px 20px rgba(255, 255, 255, 1), inset 0px 0px 4px rgba(255, 255, 255, 0), inset 7px 7px 15px rgba(55, 84, 170, .15), inset -7px -7px 20px rgba(255, 255, 255, 1), 0px 0px 4px rgba(255, 255, 255, .2) !important;
		color: #0d165f !important;
	}
}

.uploadcare--button_primary:hover,
.uploadcare--button_primary:active {
	background: transparent !important;
	border-color: transparent !important;
}

.uploadcare--menu__item_tab_camera,
.uploadcare--menu__item_tab_url,
.uploadcare--menu__item_tab_facebook,
.uploadcare--menu__item_tab_gdrive,
.uploadcare--menu__item_tab_gphotos,
.uploadcare--menu__item_tab_dropbox,
.uploadcare--menu__item_tab_instagram,
.uploadcare--menu__item_tab_evernote,
.uploadcare--menu__item_tab_flickr,
.uploadcare--menu__item_tab_onedrive,
.uploadcare--file-sources,
.uploadcare--powered-by.uploadcare--dialog__powered-by,
.uploadcare--powered-by.uploadcare--panel__powered-by,
.uploadcare--menu.uploadcare--panel__menu {
	display: none;
}

.uploadcare--menu__items,
.uploadcare--menu__item_current {
	background-color: #ffc1ae;
}

.uploadcare--panel__content {
	background-image: linear-gradient(to right, #ffc1ae, #ffd8ce 63%);
}

@media screen and (max-width: 479px) {
	.uploadcare--widget__button.uploadcare--widget__button_type_open {
		width: auto;
	}
}
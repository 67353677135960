@import 'slick-carousel/slick/slick.scss';
/* customize slider arrows color by overriding CSS variable */
$slick-arrow-color: #FFCCC8;
@import 'slick-carousel/slick/slick-theme.scss';


/* slider height */
.slick-track {
	height: 100px;
}

/* pagination dots */
.slick-dots {
	bottom: -70px;
}
.slick-dots li {
	width: 10px;
}
.slick-dots li.slick-active button {
	opacity: 0.5;
}
.slick-dots li button {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: white;
}
.slick-dots li button:before {
	color: white;
	font-size: 0px;
}
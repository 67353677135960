/* plyr customization */
:root {
	--plyr-color-main: #FF8AA4;
	// --plyr-control-spacing: 15px;
}
// .plyr__volume {
// 	min-width: 130px;
// }
// .plyr__controls__item.plyr__time {
// 	margin-left: 10px !important;
// }

// specify intlTelInput image path because default value is not relative to intlTelInput src/ but to its build/ dir
$flagsImagePath: '../../build/img/';
@import 'intl-tel-input/src/css/intlTelInput.scss';


.iti {
	width: 100%;

	.iti__flag-container {
		margin-left: 10px;

		.iti__selected-flag {

			padding: 0 6px 0 8px;
			margin-left: -11px;
			padding-left: 18px;

			border-radius: 15px;
			padding-right: 10px;

			&:focus {
				outline: none;
			}

			&::-moz-focus-inner {
				border: 0;
			}
		}

		.iti__country-list {
			width: 300px; // set by JS
			color: #ff5c8b;
			background: linear-gradient(90deg, #FFF7F7, #F5A4B7);
			border-bottom-right-radius: 15px;
			border-bottom-left-radius: 15px;
			border-top: none;
			border: none;
			margin-top: 1px;
			box-shadow: 0px 3px 4px rgba(0,0,0,.2);
			left: 5px;

			.iti__country {

				margin-bottom: 0px;
				width: 100%;
				overflow: hidden;
				
				&:nth-child(even) {
					// background-color: rgba(0, 0, 0, 0.1);
				}

				&:nth-child(odd) {
					background-color: rgba(0, 0, 0, 0.05);
				}
			}
		}
	}
}

#phone-input {
	text-indent: 8px;
	padding-left: 56px;
}
